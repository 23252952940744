import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { SelectField } from 'components/atoms/SelectField'
import { InputField } from 'components/atoms/InputField'
import { useFormikContext } from 'formik'
import { TextareaField } from 'components/atoms'
import { icons } from 'constant'
import { CompanyRegistrationType } from 'services/companyServices'
import { CompanyForm } from './CompanyForm'
import { ButtonAddField } from './ButtonAddField'

type SelectFieldProps = {
  handleAddField: (value: string) => void
  optionsKindOffice: {
    label: string
    value: number | string
  }[]
  optionsAllCities: { label: string; value: number | string }[]
  optionsCategories: {
    label: string
    value: number | string
  }[]
}
/**
 * BusinessInfoRegistration components
 * @constructor
 */
export const BusinessInfoRegistration: FC<SelectFieldProps> = ({
  handleAddField,
  optionsKindOffice,
  optionsAllCities,
  optionsCategories,
}) => {
  const { values, setFieldValue } = useFormikContext<CompanyRegistrationType>()
  /**
   * @returns function that handle next input
   */
  const handleChangeBusinesseField = (
    value: any,
    name: string,
    index: number,
  ) => {
    const valuesFiled: any = [...values.business_activity]
    valuesFiled[index][name] = value
    setFieldValue('business_activity', valuesFiled)
  }
  /**
   * @returns function that handle next input
   */
  const handleChangeOfficesField = (
    value: string | number | undefined,
    name: string,
    index: number,
  ) => {
    const valuesFiled: any = [...values.offices]
    valuesFiled[index][name] = value
    setFieldValue('offices', valuesFiled)
  }
  /**
   * @returns function that handle next input
   */
  const handleChangeCustomerField = (
    value: string | number | undefined,
    name: string,
    index: number,
  ) => {
    const valuesFiled: any = [...values.customer_company]
    valuesFiled[index][name] = value
    setFieldValue('customer_company', valuesFiled)
  }
  /**
   * @returns function that handle next input
   */
  const handleChangeSupplierField = (
    value: string | number | undefined,
    name: string,
    index: number,
  ) => {
    const valuesFiled: any = [...values.supplier_company]
    valuesFiled[index][name] = value
    setFieldValue('supplier_company', valuesFiled)
  }
  return (
    <Box marginTop="32px">
      <Text
        alignItems="center"
        display="flex"
        fontSize="18px"
        fontWeight="700"
        gap="8px"
        lineHeight="24px"
      >
        <Image src={icons.COMPANY} />
        事業情報
      </Text>
      <CompanyForm
        fieldform={[
          {
            label: '事業内容',
            components: (
              <Flex flexDirection="column" gap="8px" mb={'16px'}>
                {values.business_activity.map((field, index) => {
                  return (
                    <Flex
                      key={index}
                      flexDirection="column"
                      gap="8px"
                      paddingLeft="8px"
                    >
                      <Box>
                        <Flex alignItems="center" gap="6px" mb="6px">
                          <Text
                            fontSize="14px"
                            fontWeight="500"
                            letterSpacing="0.6px"
                            lineHeight="24px"
                          >
                            業種
                          </Text>
                          <Text
                            color="#B3B7B9"
                            fontSize="12px"
                            lineHeight="22px"
                          >
                            ※出典：日本取引所グループ「業種分類表」
                          </Text>
                        </Flex>
                        <SelectField
                          height="48px"
                          name="category_id"
                          options={optionsCategories ?? []}
                          placeholder="選択してください"
                          sizeLabel="14px"
                          valueOption={
                            field.category_id
                              ? optionsCategories.find(
                                  it => it.value === field.category_id,
                                )
                              : null
                          }
                          width="327px"
                          onChange={e =>
                            handleChangeBusinesseField(
                              e?.value,
                              'category_id',
                              index,
                            )
                          }
                        />
                      </Box>
                      <TextareaField
                        fontWeightLabel="500"
                        height="100px"
                        label="事業内容"
                        name="description"
                        placeholder="入力してください"
                        sizeLabel="14px"
                        value={field.description}
                        width="100%"
                        onChange={e =>
                          handleChangeBusinesseField(
                            e.target.value,
                            'description',
                            index,
                          )
                        }
                      />
                    </Flex>
                  )
                })}
                <Flex justifyContent="end" marginTop="8px">
                  <ButtonAddField
                    onClick={() => handleAddField('business_activity')}
                  />
                </Flex>
              </Flex>
            ),
          },
          {
            label: '事業所',
            components: (
              <Flex flexDirection="column" gap="8px" mb={'8px'}>
                {values.offices.map((field, index, currentData) => {
                  return (
                    <Flex key={index} gap="16px" paddingLeft="8px">
                      <SelectField
                        fontWeightLabel="500"
                        height="48px"
                        label="営業所区分"
                        name="office_type"
                        options={optionsKindOffice ?? []}
                        placeholder="選択してください"
                        sizeLabel="14px"
                        valueOption={
                          field.office_type
                            ? optionsKindOffice.find(
                                it => it.value === Number(field.office_type),
                              )
                            : null
                        }
                        width="327px"
                        onChange={e =>
                          handleChangeOfficesField(
                            e?.value,
                            'office_type',
                            index,
                          )
                        }
                      />
                      <SelectField
                        fontWeightLabel="500"
                        height="48px"
                        label="都市名"
                        name="city_id"
                        options={optionsAllCities}
                        placeholder="選択してください"
                        sizeLabel="14px"
                        valueOption={
                          field.city_id
                            ? optionsAllCities.find(
                                it => it.value === field.city_id,
                              )
                            : null
                        }
                        width="327px"
                        onChange={e =>
                          handleChangeOfficesField(e?.value, 'city_id', index)
                        }
                      />
                      {currentData.length === index + 1 && (
                        <Box
                          alignItems="end"
                          display="flex"
                          flex="1"
                          justifyContent="end"
                        >
                          <ButtonAddField
                            onClick={() => handleAddField('offices')}
                          />
                        </Box>
                      )}
                    </Flex>
                  )
                })}
              </Flex>
            ),
          },
          {
            label: '仕入先',
            components: (
              <Box m="8px">
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="24px"
                  marginBottom="8px"
                >
                  得意先の会社名
                </Text>
                <Grid
                  gap="16px"
                  position="relative"
                  templateColumns="repeat(2, 327px)"
                >
                  {values.customer_company.map((field, index) => {
                    return (
                      <Box key={index}>
                        <InputField
                          height="48px"
                          name="name"
                          placeholder="入力してください"
                          sizeLabel="14px"
                          value={field.name}
                          width="327px"
                          onChange={e =>
                            handleChangeCustomerField(
                              e.target.value,
                              'name',
                              index,
                            )
                          }
                        />
                      </Box>
                    )
                  })}
                </Grid>
                <Box
                  alignItems="end"
                  bottom="8px"
                  display="flex"
                  flex="1"
                  justifyContent="end"
                  mb="8px"
                  position="absolute"
                  right="12px"
                >
                  <ButtonAddField
                    onClick={() => handleAddField('customer_company')}
                  />
                </Box>
              </Box>
            ),
          },
          {
            label: '得意先',
            components: (
              <Box m="8px">
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="24px"
                  marginBottom="8px"
                >
                  得意先の会社名
                </Text>
                <Grid
                  gap="16px"
                  position="relative"
                  templateColumns="repeat(2, 327px)"
                >
                  {values.supplier_company.map((field, index) => {
                    return (
                      <Box key={index}>
                        <InputField
                          height="48px"
                          name="name"
                          placeholder="入力してください"
                          sizeLabel="14px"
                          value={field.name}
                          width="327px"
                          onChange={e =>
                            handleChangeSupplierField(
                              e.target.value,
                              'name',
                              index,
                            )
                          }
                        />
                      </Box>
                    )
                  })}
                </Grid>
                <Box
                  alignItems="end"
                  bottom="8px"
                  display="flex"
                  flex="1"
                  justifyContent="end"
                  mb="8px"
                  position="absolute"
                  right="12px"
                >
                  <ButtonAddField
                    onClick={() => handleAddField('supplier_company')}
                  />
                </Box>
              </Box>
            ),
          },
        ]}
        marginTop="16px"
        mt={'12px'}
      />
    </Box>
  )
}
