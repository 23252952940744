import React, { FC } from 'react'

import {
  Flex,
  Text,
  Divider,
  Image,
  Box,
  CircularProgress,
  Center,
} from '@chakra-ui/react'
import { FormikProvider } from 'formik'
import moment from 'moment'
import ShowIcon from 'utils/showIconTable'
import { ROUTES, icons } from 'constant'
import { isEmpty } from 'lodash'
import NewsPdf from './components/NewsPdf'
import PdfPagination from './components/PdfPagination'
import { CardPostContentProps, useCardPostContent } from './hook'

/**
 * CardPostContent molecules
 * @constructor
 */
export const CardPostContent: FC<CardPostContentProps> = props => {
  const {
    formik,
    handleToggleNewsFavorite,
    page,
    handleSetPagePdf,
    values,
    newsDetail,
    onCloseDrawer,
    setCunrentPage,
    cunrentPage,
    navigate,
  } = useCardPostContent(props)

  return (
    <FormikProvider value={formik}>
      {newsDetail.isLoading ? (
        <Flex
          alignItems="center"
          bg="gray.600"
          h="calc(100vh - 88px)"
          justifyContent="center"
          w="100%"
        >
          <CircularProgress isIndeterminate color="blue.900" size={10} />
        </Flex>
      ) : (
        <>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            mb="12px"
            w="100%"
          >
            <Flex alignItems="center" gap="30px" w="100%">
              <Text
                color="black.100"
                fontSize="lg"
                fontWeight="bold"
                letterSpacing="-0.02em"
                lineHeight="tall"
                p={'0px 8px'}
              >
                {newsDetail.data && newsDetail.data.news_title}
              </Text>
              <Image
                cursor={'pointer'}
                src={newsDetail.favorite ? icons.STAR : icons.EMPTY_STAR}
                onClick={handleToggleNewsFavorite}
              />
            </Flex>
            <Box cursor="pointer" onClick={onCloseDrawer}>
              <Image src={icons.ICON_DRAWER} />
            </Box>
          </Flex>

          <Flex alignItems="center" justifyContent="space-between">
            <Flex gap={4} height="40px" mb="24px">
              <Flex direction="column" justifyContent="space-between">
                <Text
                  color="black.100"
                  fontSize="xs"
                  fontWeight="normal"
                  lineHeight="12px"
                  opacity={0.3}
                >
                  日付
                </Text>
                <Text
                  color="black.100"
                  fontSize="sm"
                  fontWeight="normal"
                  letterSpacing="normal"
                  lineHeight="16px"
                >
                  {newsDetail.data
                    ? moment(newsDetail.data.news_date).format('YYYY/MM/DD')
                    : ''}
                </Text>
              </Flex>
              {!newsDetail.data.company_report?.file_path && (
                <>
                  <Divider orientation="vertical" />
                  <Flex direction="column" justifyContent="space-between">
                    <Text
                      color="black.100"
                      fontSize="xs"
                      fontWeight="normal"
                      lineHeight="12px"
                      opacity={0.3}
                    >
                      記事スコア
                    </Text>

                    <Flex justify={'center'}>
                      <Text
                        color="black.100"
                        fontSize="2xl"
                        fontWeight="normal"
                      >
                        {
                          String(
                            newsDetail.data.score_article?.toFixed(1) || 0,
                          ).split('.')[0]
                        }
                        {'.'}
                      </Text>
                      <Text
                        color="black.100"
                        fontSize="lg"
                        fontWeight="normal"
                        lineHeight="36px"
                      >
                        {String(
                          newsDetail.data.score_article?.toFixed(1) || 0,
                        ).split('.')[1] ?? 0}
                      </Text>
                    </Flex>
                  </Flex>
                </>
              )}
              <Divider orientation="vertical" />
              <Flex direction="column" justifyContent="space-between">
                <Text
                  color="black.100"
                  fontSize="xs"
                  fontWeight="normal"
                  lineHeight="12px"
                  opacity={0.3}
                >
                  判定
                </Text>
                <Center>
                  <ShowIcon
                    changeColor={false}
                    value={newsDetail.data.sentiment}
                  />
                </Center>
              </Flex>
              <Divider orientation="vertical" />
              <Flex direction="column" justifyContent="space-between">
                <Text
                  color="black.100"
                  fontSize="xs"
                  fontWeight="normal"
                  lineHeight="12px"
                  opacity={0.3}
                >
                  関連企業
                </Text>

                <Text
                  color="#0084FF"
                  cursor={'pointer'}
                  fontSize="sm"
                  fontWeight="normal"
                  letterSpacing="normal"
                  lineHeight="14px"
                  maxW="110px"
                  onClick={() => {
                    if (newsDetail.data.company_id) {
                      navigate(
                        `${ROUTES.COMPANY}/${newsDetail.data.company_id}`,
                      )
                    }
                  }}
                >
                  {newsDetail.data.subject_company ?? ''}
                </Text>
              </Flex>
              <Divider orientation="vertical" />
              <Flex direction="column" justifyContent="space-between">
                <Text
                  color="black.100"
                  fontSize="xs"
                  fontWeight="normal"
                  lineHeight="12px"
                  opacity={0.3}
                >
                  ソース
                </Text>
                <Text
                  color="black.100"
                  fontSize="sm"
                  fontWeight="normal"
                  letterSpacing="normal"
                  lineHeight="16px"
                  maxW="100px"
                >
                  {newsDetail.data.sauce ?? ''}
                </Text>
              </Flex>
            </Flex>
            {!isEmpty(newsDetail.data.company_report?.file_path) && (
              <PdfPagination
                handleSetPagePdf={handleSetPagePdf}
                page={page}
                setCunrentPage={setCunrentPage}
                value={Number(values.page)}
              />
            )}
          </Flex>
          {isEmpty(newsDetail.data.company_report?.file_path) ? (
            <Text
              color="black.100"
              fontSize="md"
              fontWeight="normal"
              lineHeight="28px"
              textAlign="justify"
              whiteSpace="break-spaces"
            >
              {newsDetail.data.news_body}
            </Text>
          ) : !isEmpty(newsDetail.data.company_report?.file_path) ? (
            <NewsPdf
              cunrentPage={cunrentPage}
              file={
                newsDetail.data.company_report?.file_path
                  ? newsDetail.data.company_report?.file_path
                  : ''
              }
            />
          ) : (
            ''
          )}
        </>
      )}
    </FormikProvider>
  )
}
