import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { chatAIServices } from 'services'
import { Errors, Option } from 'types/conmon'
import * as yup from 'yup'
import { ConsultationConverted } from 'services/chatAIServices'
import { extractContent } from 'utils/extractContent'
import { configs } from 'configs'
import { FormValue } from '../MainChat'

const regexUrl = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
const KEY_CODE_ENTER = 13
const TYPE_FILE = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/msword',
]

export const validationSchema = yup.object().shape({
  url: yup
    .string()
    .test('url', '適当なURL形式を入力してください', value =>
      regexUrl.test(value ?? ''),
    ),
})

/**
 * @returns function that handle any function for consultation list
 */
export const useConsultationContentList = (
  question: string,
  setQuestion: Dispatch<SetStateAction<string>>,
  setPromptType: Dispatch<SetStateAction<string>>,
  setIsAgreeConsult: Dispatch<SetStateAction<boolean>>,
  consultationId: number | null,
  setConsultationId: Dispatch<SetStateAction<number | null>>,
  formValue: FormValue,
  setFormValue: Dispatch<SetStateAction<FormValue>>,
) => {
  const toast = useToast()
  const [consultationList, setConsultationList] =
    useState<ConsultationConverted[]>()
  const [consultation, setConsultation] = useState<ConsultationConverted>()
  const [selectedCategory, setSelectedCategory] = useState<Option>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState<boolean>(false)
  const [errors, setErrors] = useState<Errors>()
  const {
    isOpen: isOpenDrawerRight,
    onOpen: onOpenDrawerRight,
    onClose: onCloseDrawerRight,
  } = useDisclosure({ defaultIsOpen: true })
  const [isShowModalSelectArticle, setIsShowModalSelectArticle] =
    useState(false)
  const [optionCategory, setOptionCategory] = useState<Option[]>([])
  const [nameFileUpload, setNameFileUpload] = useState('')

  /**
   * @returns function that handle open select category
   */
  const handleOpenSelectCategory = useCallback(async () => {
    try {
      const { data } = await chatAIServices.getOptionCategory()
      const options = data.map(o => ({
        label: o.ja_name,
        value: o.id,
        icon: configs.apiURL + '/' + o.icons,
      }))
      setOptionCategory(options)
    } catch (error) {}
  }, [])

  useEffect(() => {
    handleOpenSelectCategory()
  }, [handleOpenSelectCategory])

  /**
   * @returns function that handle change favorite consultation
   */
  const handleChangeFavorite = async (
    event: MouseEvent<HTMLButtonElement>,
    id: number,
  ) => {
    event.stopPropagation()
    const newState = consultationList?.map(consultation =>
      consultation.id === id
        ? { ...consultation, is_favorite: !consultation.is_favorite }
        : consultation,
    )
    setConsultationList(newState)

    const findConsultations = consultationList?.find(
      consultation => consultation.id === id,
    )
    if (!findConsultations) return
    const body = {
      status: !findConsultations.is_favorite,
    }
    await chatAIServices.toggleFavorite(id, body)
  }

  /**
   * @returns function that handle get consultation
   */
  const handleGetConsultation = useCallback(
    async (id: number) => {
      try {
        setIsLoading(true)
        const params = {
          category_id: id,
          type_prompt: 'all_prompt',
        }
        const { data } = await chatAIServices.getConsultationList(params)
        setConsultationList(data)
      } finally {
        setIsLoading(false)
        setConsultation(undefined)
        setConsultationId(null)
      }
    },
    [setConsultationId],
  )

  /**
   * @returns function that handle select category
   */
  const handleSelectCategory = (option: Option | unknown) => {
    const selectedOption = option as Option
    if (selectedOption.value === selectedCategory?.value) return
    setSelectedCategory(selectedOption)
    handleGetConsultation(Number(selectedOption.value))
  }

  /**
   * @returns function that handle change url
   */
  const handleChangeUrl = (value: string) => {
    setFormValue({ url: value })
    setPromptType('url')

    setErrors({ url: { message: '' } })
    // if (!value) {
    //   setPromptType('')
    //   setQuestion('')
    //   setConsultationId(null)
    //   setConsultation(undefined)
    // }
  }

  const validation = useCallback(() => {
    setErrors(undefined)
    try {
      validationSchema.validateSync(formValue, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          const newErrors: Errors = {}
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
          setErrors(newErrors)
          return false
        }
      }
    }
    return true
  }, [formValue])

  const handleReplaceFileAndUrl = (
    question: string,
    promptUrlName: string,
    promptFileName: string,
  ) => {
    let newQuestion = question
    const isPdfOrURL = ['\n＃対象記事', '\n＃対象URL'].some(word =>
      newQuestion.includes(word),
    )

    if (isPdfOrURL) {
      let indexFile = newQuestion.indexOf('\n＃対象記事')
      let indexUrl = newQuestion.indexOf('\n＃対象URL')
      let index = indexFile !== -1 ? indexFile : indexUrl

      if (index !== -1) {
        newQuestion = newQuestion.slice(0, index)
      }
    }

    return (newQuestion = `${newQuestion}${
      formValue.url ? promptUrlName : promptFileName
    }`)
  }

  /**
   * @returns function that handle format prompt
   */
  const handleFormatPrompt = (consultation?: ConsultationConverted) => {
    const promptFileName = formValue.file
      ? `\n＃対象記事\n${formValue.file?.name}`
      : ''
    const promptUrlName = formValue.url ? `\n＃対象URL\n${formValue.url}` : ''

    if (!consultation?.prompt) {
      let newQuestion = handleReplaceFileAndUrl(
        question,
        promptUrlName,
        promptFileName,
      )
      setQuestion(newQuestion)
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { header, status, article, constraints, outputConditions } =
      extractContent(consultation.prompt)

    let formatPrompt = `${header}\n\n${status ? `#条件\n${status}\n\n` : ''}${
      constraints ? `＃制約条件\n${constraints}\n\n` : ''
    }${outputConditions ? `＃出力条件\n${outputConditions}\n` : ''}${
      formValue.url ? promptUrlName : promptFileName
    }`
    let newQuestion = handleReplaceFileAndUrl(
      formatPrompt,
      promptUrlName,
      promptFileName,
    )
    setQuestion(newQuestion)
  }

  /**
   * @returns function that handle sent url to API
   */
  const handleSubmitUrl = async (event: any) => {
    if (
      (event.type === 'keydown' && event.keyCode === KEY_CODE_ENTER) ||
      event.type === 'blur'
    ) {
      event.preventDefault()
      if (validation()) {
        try {
          // const body: BodyUploadUrl = {
          //   attachment_url: formValue.url,
          // }
          // await chatAIServices.uploadUrl(body)
          handleFormatPrompt(consultation)
        } catch (error) {
          toast({
            status: 'error',
            description:
              '何らかのエラーが発生しました。もう一度お試しください。',
          })
        }
      }
    }
  }

  /**
   * @returns function that handle upload file
   */
  const handleUploadFile = async (file: File) => {
    const maxSizeInBytes = 25 * 1024 * 1024 // 25MB
    if (file.size > maxSizeInBytes) {
      return toast({
        status: 'error',
        description: 'ファイルサイズが25MBを超えています',
      })
    }

    if (!TYPE_FILE.includes(file.type)) {
      return toast({
        status: 'error',
        description: 'DOCかPDFファイルをアップロードしてください',
      })
    }

    setIsLoadingUploadFile(true)
    setFormValue({ file: file })
    try {
      const formData = new FormData()
      formData.append('file', file)
      await chatAIServices.uploadFile(formData)
      setNameFileUpload(file.name)
      setPromptType('file')
    } catch (error) {
      toast({
        status: 'error',
        description: '何らかのエラーが発生しました。もう一度お試しください。',
      })
    } finally {
      setIsLoadingUploadFile(false)
      setErrors({ url: { message: '' } })
      const promptFileName = file ? `\n＃対象記事\n${file.name}` : ''

      if (consultation?.prompt) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { header, status, article, constraints, outputConditions } =
          extractContent(consultation.prompt)

        const formatPrompt = `${header}\n\n${
          status ? `#条件\n${status}\n\n` : ''
        }${constraints ? `＃制約条件\n${constraints}\n\n` : ''}${
          outputConditions ? `＃出力条件\n${outputConditions}\n\n` : ''
        }${promptFileName}`

        setQuestion(formatPrompt)
      } else {
        setQuestion(prev => prev + promptFileName)
      }
    }
  }

  /**
   * @returns function that handle select prompt
   */
  const handleSelectPrompt = (id: number) => {
    const findConsultation = consultationList?.find(item => item.id === id)
    if (!findConsultation) return
    setConsultation(findConsultation)
    setConsultationId(id)

    if (formValue.url) {
      setPromptType('url')
    } else if (formValue.file) {
      setPromptType('file')
    }
    if (formValue.url || formValue.file) {
      handleFormatPrompt(findConsultation)
      return
    }
    setIsAgreeConsult(true)
    setQuestion(findConsultation.prompt)
  }

  /**
   * @returns function that handle select prompt
   */
  const handleClearFileUploaded = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    event.stopPropagation()
    setFormValue({ file: undefined })
    // setQuestion('')
    // setConsultationId(null)
    // setConsultation(undefined)
    setNameFileUpload('')
  }

  return {
    errors,
    formValue,
    isLoading,
    consultation,
    consultationId,
    consultationList,
    selectedCategory,
    isOpenDrawerRight,
    isLoadingUploadFile,
    isShowModalSelectArticle,
    optionCategory,
    nameFileUpload,
    handleChangeUrl,
    handleSubmitUrl,
    setConsultation,
    handleUploadFile,
    onOpenDrawerRight,
    handleSelectPrompt,
    onCloseDrawerRight,
    handleChangeFavorite,
    handleSelectCategory,
    handleClearFileUploaded,
    setIsShowModalSelectArticle,
    setNameFileUpload,
    setConsultationList,
    setSelectedCategory,
  }
}
