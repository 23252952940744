import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authSlice from 'context/Auth/auth.slice'
import breadcrumbSlice from 'context/Breadcrumb/breadcrumb.slice'

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [breadcrumbSlice.name]: breadcrumbSlice.reducer,
})

/**
 * make store
 */
const store = configureStore({
  reducer: rootReducer,
  devTools: true,
})

export type RootState = ReturnType<typeof store.getState>

export default store
