/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react'
import { Box, Flex, SkeletonText, Text } from '@chakra-ui/react'
import { FormikProvider } from 'formik'
// import { MoreVertical } from 'components/atoms/Icons'
import { SelectField } from 'components/atoms'
import ModalTalkStory from '../ModalTalkStory'
import ReactMarkdown from '../ReactMarkdown'
import { TalkStoryProps, useTalkStory } from './hook'

/**
 * TalkStory molecules
 * @constructor
 */
export const TalkStory: FC<TalkStoryProps> = props => {
  const {
    formik,
    options,
    values,
    setAIAnswer,
    setFieldValue,
    AIAnswer,
    toggleExpanded,
    expanded,
    setExpanded,
    stateAIAnswer,
    isShow,
    ref,
    isDisableSelect,
  } = useTalkStory(props)
  return (
    <FormikProvider value={formik}>
      <Flex
        bgColor="white.base"
        borderRadius={4}
        direction="column"
        h="100%"
        padding="12px 24px 16px 24px"
        w="100%"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb="4px"
          w="100%"
        >
          <Text
            className="drag-horizontal"
            color="black.100"
            cursor="pointer"
            display="inline-block"
            fontSize="lg"
            fontWeight="bold"
            letterSpacing="-0.02em"
            lineHeight="tall"
            minW={'fit-content'}
            px="8px"
          >
            トークネタ
          </Text>
          {/* <MoreVertical color="gray.800" fontSize="2xl" opacity={0.3} /> */}
        </Flex>
        <Flex gap="16px" mb="16px">
          <SelectField
            heightListOptions="224px"
            isDisabled={isDisableSelect}
            minHeight="40px"
            name="selectIndustry"
            options={options.industry}
            placeholder="業界"
            sizePlaceholder="14px"
            valueOption={options.industry.find(
              it => it.value === values.selectIndustry,
            )}
            width="100%"
            onChange={e => {
              if (e?.value !== values.selectIndustry) {
                setAIAnswer('')
                setFieldValue('selectIndustry', e?.value)
                // setFieldValue('selectTalkStory', null)
              }
            }}
          />
          <SelectField
            heightListOptions="224px"
            isDisabled={isDisableSelect}
            minHeight="40px"
            name="selectTalkStory"
            options={options.story}
            placeholder="トークテーマ"
            sizePlaceholder="14px"
            valueOption={options.story.find(
              it => it.value === values.selectTalkStory,
            )}
            width="100%"
            onChange={e => {
              if (e?.value !== values.selectTalkStory) {
                setFieldValue('selectTalkStory', e?.value)
                setAIAnswer('')
              }
            }}
          />
          <Box w="100%"></Box>
        </Flex>
        <>
          <Box ref={ref} w="100%">
            {stateAIAnswer.loading ? (
              <SkeletonText noOfLines={6} skeletonHeight="4" spacing="4" />
            ) : Boolean(AIAnswer) ? (
              <Box maxHeight={'168px'} overflow={'auto'}>
                <ReactMarkdown
                  bgColor="#FEFEFE"
                  data={AIAnswer}
                  isCopyBtn={true}
                  isDone={stateAIAnswer.done}
                />
              </Box>
            ) : (
              <Flex justify={'center'}>
                <Text
                  color="black.100"
                  fontSize={'16px'}
                  fontWeight="normal"
                  id="text-element"
                  lineHeight="shorter"
                  maxHeight={'168px'}
                  overflow={'hidden'}
                  textAlign="justify"
                  whiteSpace={'pre-wrap'}
                >
                  {'データがありません。'}
                </Text>
              </Flex>
            )}
            {AIAnswer && isShow && (
              <Flex justifyContent="flex-end">
                <Text
                  color="blue.900"
                  cursor="pointer"
                  display="inline-block"
                  fontSize="sm"
                  fontWeight="normal"
                  lineHeight="shorter"
                  textAlign="end"
                  onClick={toggleExpanded}
                >
                  別ウィンドウで表示
                </Text>
              </Flex>
            )}
          </Box>
        </>
      </Flex>
      <ModalTalkStory
        isDisableSelect={isDisableSelect}
        isOpen={expanded}
        options={options}
        setAIAnswer={setAIAnswer}
        setValue={setFieldValue}
        stateAIAnswer={stateAIAnswer}
        story={AIAnswer}
        values={values}
        onClose={() => {
          setExpanded(false)
        }}
      />
    </FormikProvider>
  )
}
