/**
 * @returns function that handle extract consultation
 */
export function extractContent(inputString: string) {
  const lines = inputString.split('\n')
  let header = ''
  let status = ''
  let article = ''
  let constraints = ''
  let outputConditions = ''
  let currentSection = ''

  for (const line of lines) {
    if (line.startsWith('＃') || line.startsWith('#')) {
      currentSection = line
    } else {
      if (currentSection.trim() === '＃制約条件') {
        constraints += line + '\n'
      } else if (
        currentSection.trim() === '＃出力条件' ||
        currentSection.trim() === '＃出力形式'
      ) {
        outputConditions += line + '\n'
      } else if (
        currentSection.trim() === '＃対象記事' ||
        currentSection.trim() === '＃対象記事：'
      ) {
        article += line + '\n'
      } else if (
        currentSection.trim() === '#条件' ||
        currentSection.trim() === '＃条件'
      ) {
        status += line + '\n'
      } else {
        header += line + '\n'
      }
    }
  }
  header = header.trim()
  status = status.trim()
  article = article.trim()
  constraints = constraints.trim()
  outputConditions = outputConditions.trim()

  return {
    header,
    status,
    article,
    constraints,
    outputConditions,
  }
}
