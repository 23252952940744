import React, { FC } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  IconButton,
  Text,
  useTheme,
} from '@chakra-ui/react'
import { RightDrawerIcon } from 'components/fromAdvisor/elements/Icons'
import { Select } from 'components/fromAdvisor/elements'
import { Plus } from 'components/atoms/Icons'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'constant'
import { ConsultationContentList } from './List'
import { ConsultationContentProps } from './ConsultationContentList.type'
import { InputUrl } from './InputUrl'

/**
 * ConsultationContentContainer component page.
 * @return {JSX.Element} ConsultationContentContainer component.
 */
export const ConsultationContentContainer: FC<ConsultationContentProps> = ({
  consultation,
  consultationList,
  selectedCategory,
  onChangeFavorite,
  isLoading,
  onCloseDrawerRight,
  onSelectCategory,
  url,
  file,
  errors,
  onChangeUrl,
  onSubmitUrl,
  onUploadFile,
  onSelectPrompt,
  onClearFileUploaded,
  isLoadingUploadFile,
  setIsShowModalSelectArticle,
  setOldQuestion,
  question,
  questionAskMore,
  isAskMore,
  optionCategory,
  nameFileUpload,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <Flex
      bg={'white.150'}
      borderBottomRightRadius={4}
      flexDirection={'column'}
      h={'full'}
      justifyContent={'space-between'}
    >
      <Flex flexDirection={'column'} gap={6} p={6}>
        <Flex alignItems={'center'} justifyContent={'space-between'}>
          <Text
            color={'black.100'}
            fontSize={'xl'}
            fontWeight={'bold'}
            lineHeight={theme.lineHeights.base}
          >
            相談メニュー
          </Text>
          <Flex alignItems={'center'} gap={6}>
            <IconButton
              _hover={{
                background: 'transparent',
              }}
              aria-label="right drawer"
              bg={'transparent'}
              h={6}
              icon={<RightDrawerIcon />}
              minW={6}
              onClick={onCloseDrawerRight}
            />
          </Flex>
        </Flex>
        <Box w={'full'}>
          <Select
            options={optionCategory}
            placeholder={'選択してください'}
            value={selectedCategory}
            onChange={onSelectCategory}
          />
        </Box>
        <InputUrl
          errors={errors}
          file={file}
          isAskMore={isAskMore}
          isLoadingUploadFile={isLoadingUploadFile}
          nameFileUpload={nameFileUpload}
          question={question}
          questionAskMore={questionAskMore}
          setIsShowModalSelectArticle={setIsShowModalSelectArticle}
          setOldQuestion={setOldQuestion}
          url={url}
          onChangeUrl={onChangeUrl}
          onClearFileUploaded={onClearFileUploaded}
          onSubmitUrl={onSubmitUrl}
          onUploadFile={onUploadFile}
        />
        {isLoading && (
          <Flex
            alignItems={'center'}
            h={'full'}
            justifyContent={'center'}
            w={'full'}
          >
            <CircularProgress isIndeterminate color="blue.900" size={10} />
          </Flex>
        )}

        <ConsultationContentList
          consultation={consultation}
          consultationList={consultationList}
          onChangeFavorite={onChangeFavorite}
          onSelectPrompt={onSelectPrompt}
        />
      </Flex>

      <Flex justifyContent="flex-end">
        <Button
          _hover={{
            bgColor: 'white.50',
          }}
          bgColor="white.50"
          border="solid 1px"
          borderColor="blue.900"
          borderRadius="12px"
          color="blue.900"
          fontSize="sm"
          fontWeight="bold"
          h="48px"
          leftIcon={<Plus color="blue.900" fontSize={24} />}
          lineHeight="shorter"
          mr="24px"
          my="22px"
          padding="8px 16px"
          w="176px"
          onClick={() => navigate(ROUTES.PROMPT_REGISTRATION)}
        >
          Myプロンプト登録
        </Button>
      </Flex>
    </Flex>
  )
}
