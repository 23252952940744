import React, { FC } from 'react'
import { Avatar, AutoResizeTextarea } from 'components/fromAdvisor/elements'
import { Box, Flex, IconButton, useTheme } from '@chakra-ui/react'
import { PaperPlaneIcon } from 'components/fromAdvisor/elements/Icons'
import { ChatBoxProps } from './MainChat.types'

/**
 * ChatBox component page.
 * @return {JSX.Element} ChatBox component.
 */
export const ChatBox: FC<ChatBoxProps> = ({
  question,
  onSubmit,
  onChangeInput,
  onEnter,
  avatar,
}) => {
  const theme = useTheme()

  return (
    <Flex
      bg={'white.50'}
      borderColor={'purple.250'}
      borderRadius={'xl'}
      borderWidth={'3px'}
      boxShadow={theme.boxShadows.insetInputBase}
      gap={3.5}
      mb={10}
      minH={'280px'}
      px={6}
      py={8}
      w={'full'}
    >
      <Box maxH={15} minW={15} w={15}>
        <Avatar src={avatar} />
      </Box>
      <Flex
        alignItems={'flex-end'}
        flexDirection={'column'}
        h={'full'}
        w={'full'}
      >
        <AutoResizeTextarea
          _focusVisible={{
            border: 'none',
          }}
          _placeholder={{
            opacity: 0.3,
            color: 'black.100',
          }}
          border={'none'}
          color={'black.100'}
          fontSize={'md'}
          fontWeight={'normal'}
          h={'full'}
          lineHeight={'30px'}
          minH={'192px'}
          overflow={'auto'}
          placeholder={
            '右の検索からAIへの相談内容をお選びください。自由入力もできます。'
          }
          resize={'none'}
          value={question}
          w={'full'}
          onChange={onChangeInput}
          onKeyDown={onEnter}
        />
        <IconButton
          _hover={{
            background: 'transparent',
          }}
          aria-label="send message"
          bg={'transparent'}
          icon={<PaperPlaneIcon />}
          zIndex={theme.zIndices.docked}
          onClick={onSubmit}
        />
      </Flex>
    </Flex>
  )
}
