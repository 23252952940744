import React, { FC, useEffect, useMemo } from 'react'
import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react'

import { FormikProvider } from 'formik'
import { Close, MoreVertical } from 'components/atoms/Icons'
import { DataTable, InputField, SelectField } from 'components/atoms'
import { createColumnHelper } from '@tanstack/react-table'
import { DatePicker, Modal } from 'components/fromAdvisor/elements'
import { CSVLink } from 'react-csv'
import { NewsType } from 'services/newServices'
import ShowIcon from 'utils/showIconTable'
import { Link } from 'react-router-dom'
import moment from 'moment'
import SaveKeyModal from '../SaveKeyModal'
import { InitialValuesType, SearchPostProps, useSearchPost } from './hook'
import NewsDateColumn from './Component/NewsDateColumn'
import SubjectCompanyColumn from './Component/SubjectCompanyColumn'
import NormalColumn from './Component/NormalColumn'
import NewsDetailColumn from './Component/NewsDetailColumn'
import NewsBodyColumn from './Component/NewsBodyColumn'
import ScoreColumn from './Component/ScoreColumn'
import {
  categoryMeta,
  contactPerson,
  newsDate,
  newsTitle,
  productsMeta,
  sauceMeta,
  score,
  sentimentMeta,
  subjectCompany,
} from './metaColumn'

/**
 * SearchPost molecules
 */
export const SearchPost: FC<SearchPostProps> = props => {
  const {
    csvInstance,
    searchKey,
    setSearchKey,
    isShowSaveFilterModal,
    setIsShowSaveFilterModal,
    isLoading,
    isLoadingSaveKey,
    optionsPopover,
    dataNews,
    filterKey,
    setFilterKey,
    handleDeleteConditionFilter,
    handleConvertIcon,
    dataMoreVertical,
    dataExport,
    handleSaveConditionFilter,
    pagination,
    setPagination,
    handleExportCsv,
    formik,
    fetchDataNews,
    handleResetFilterKey,
    handleSort,
    optionTypeReport,
    getColumnConfig,
    isDetailCompany = false,
    page,
    navigate,
    companyId,
  } = useSearchPost(props)
  const columnHelper = createColumnHelper<NewsType>()
  const isScrollTable = page === 'card-news'
  const {
    values: {
      start_date,
      end_date,
      is_news_reports,
      search_key,
      sort_by,
      contact_person_name,
      news_title,
      score_article_gte,
      score_article_lte,
      favorite,
      sentiment,
      subject_company,
      category,
      sauce,
      products,
    },
    setFieldValue,
    errors,
    setValues,
    resetForm,
  } = formik

  // Dashboard screen
  const columnsDashboard = React.useMemo(
    () =>
      [
        columnHelper.accessor('news_date', {
          id: 'news_date',
          cell: info => <NewsDateColumn date={info.getValue()} />,
          header: '日付',
          size: 140,
          meta: {
            ...newsDate,
            sort_by:
              formik.values.sort_by.split(':')[0] === 'news_date'
                ? formik.values.sort_by.split(':')[1]
                : 'desc',
            minWidthColumn: '140px',
          },
        }),
        columnHelper.accessor('subject_company', {
          id: 'subject_company',
          cell: info => {
            const company_id = info.row.original.company_id
            return (
              <SubjectCompanyColumn
                companyId={company_id}
                isDetailCompany={isDetailCompany}
                label={info.getValue() ?? ''}
                typeReport={is_news_reports}
              />
            )
          },
          header: '会社名',
          size: 163,
          meta: subjectCompany,
        }),
        columnHelper.accessor('category', {
          id: 'category',
          cell: info => <NormalColumn label={info.getValue() ?? ''} />,
          header: '業界',
          size: 106,
          meta: {
            ...categoryMeta,
            options: optionsPopover.category,
          },
        }),
        getColumnConfig(
          columnHelper.accessor('contact_person_name', {
            id: 'contact_person_name',
            cell: info => <NormalColumn label={info.getValue() ?? ''} />,
            header: '担当者',
            size: 128,
            meta: contactPerson,
          }),
        ),
        columnHelper.accessor('news_title', {
          id: 'news_title',
          cell: info => {
            const {
              list_news_id: id,
              is_favorite: isFavorite,
              list_news_ai_id: newsAiId,
              company_id,
            } = info.row.original

            return (
              <NewsDetailColumn
                company_id={company_id}
                isFavorite={isFavorite}
                label={info.getValue()}
                newsAiId={newsAiId}
                newsId={id}
              />
            )
          },
          header: '記事タイトル',
          size: 290,
          meta: { ...newsTitle, minWidthColumn: '290px' },
        }),
        getColumnConfig(
          columnHelper.accessor('score_article', {
            id: 'score_article',
            cell: info => {
              let valueString = info.getValue()
                ? String(info.getValue()?.toFixed(1))
                : '0'
              let valueIntegerPart = valueString.split('.')[0]
              let valueDecimalPart = valueString.split('.')[1] || '0'
              return (
                <ScoreColumn
                  valueDecimalPart={valueDecimalPart}
                  valueIntegerPart={valueIntegerPart}
                />
              )
            },
            header: '記事スコア',
            size: 155,
            meta: {
              ...score,
              sort_by:
                formik.values.sort_by.split(':')[0] === 'score_article'
                  ? formik.values.sort_by.split(':')[1]
                  : 'desc',
              minWidthColumn: '155px',
            },
          }),
        ),
        columnHelper.accessor('sentiment', {
          id: 'sentiment',
          cell: info => (
            <Text textAlign="center">
              <ShowIcon changeColor={false} value={info.getValue()} />
            </Text>
          ),
          header: '判定',
          size: 97,
          meta: sentimentMeta,
        }),
        columnHelper.accessor('sauce', {
          id: 'sauce',
          cell: info => <NormalColumn label={info.getValue() ?? ''} />,
          header: 'ソース',
          size: 112,
          meta: {
            ...sauceMeta,
            options:
              is_news_reports === 'false'
                ? optionsPopover.sauce.filter(it => it.label === '時事通信')
                : optionsPopover.sauce.filter(it => it.label !== '時事通信'),
          },
        }),
        getColumnConfig(
          columnHelper.accessor('products', {
            id: 'products',
            cell: info => {
              const valueProducts =
                info.getValue().map(item => item.product) || []
              return <NormalColumn label={valueProducts.join('／') ?? ''} />
            },
            header: '対象商品',
            size: 118,
            meta: {
              ...productsMeta,
              options: optionsPopover.product,
            },
          }),
        ),
      ].filter(Boolean),
    [
      columnHelper,
      formik.values.sort_by,
      getColumnConfig,
      isDetailCompany,
      optionsPopover.category,
      optionsPopover.product,
      optionsPopover.sauce,
      is_news_reports,
    ],
  )
  // CompanyDetail screen
  const columnsCompany = React.useMemo(
    () =>
      [
        columnHelper.accessor('news_date', {
          id: 'news_date',
          cell: info => <NewsDateColumn date={info.getValue()} />,
          header: '日付',
          size: 140,
          meta: {
            ...newsDate,
            sort_by:
              formik.values.sort_by.split(':')[0] === 'news_date'
                ? formik.values.sort_by.split(':')[1]
                : 'desc',
            minWidthColumn: '140px',
          },
        }),
        columnHelper.accessor('news_title', {
          id: 'news_title',
          cell: info => {
            const {
              list_news_id: id,
              is_favorite: isFavorite,
              list_news_ai_id: newsAiId,
              company_id,
            } = info.row.original
            return (
              <NewsDetailColumn
                company_id={company_id}
                isFavorite={isFavorite}
                label={info.getValue()}
                newsAiId={newsAiId}
                newsId={id}
              />
            )
          },
          header: '記事タイトル',
          size: 280,
          meta: { ...newsTitle, minWidthColumn: '280px' },
        }),
        columnHelper.accessor('abbreviation_300', {
          cell: info => {
            const res = info.row.original
            return <NewsBodyColumn content={res.abbreviation_300 ?? ''} />
          },
          size: 270,
          header: '要約',
        }),
        getColumnConfig(
          columnHelper.accessor('score_article', {
            id: 'score_article',
            cell: info => {
              let valueString = info.getValue()
                ? String(info.getValue()?.toFixed(1))
                : '0'
              let valueIntegerPart = valueString.split('.')[0]
              let valueDecimalPart = valueString.split('.')[1] || '0'
              return (
                <ScoreColumn
                  valueDecimalPart={valueDecimalPart}
                  valueIntegerPart={valueIntegerPart}
                />
              )
            },
            header: '記事スコア',
            size: 155,
            meta: {
              ...score,
              sort_by:
                formik.values.sort_by.split(':')[0] === 'score_article'
                  ? formik.values.sort_by.split(':')[1]
                  : 'desc',
              minWidthColumn: '155px',
            },
          }),
        ),
        columnHelper.accessor('sentiment', {
          id: 'sentiment',
          cell: info => (
            <Text textAlign="center">
              <ShowIcon changeColor={false} value={info.getValue()} />
            </Text>
          ),
          header: '判定',
          size: 107,
          meta: sentimentMeta,
        }),
        columnHelper.accessor('sauce', {
          id: 'sauce',
          cell: info => <NormalColumn label={info.getValue() ?? ''} />,
          header: 'ソース',
          size: 122,
          meta: {
            ...sauceMeta,
            options:
              is_news_reports === 'false'
                ? optionsPopover.sauce.filter(it => it.label === '時事通信')
                : optionsPopover.sauce.filter(it => it.label !== '時事通信'),
          },
        }),
        getColumnConfig(
          columnHelper.accessor('products', {
            id: 'products',
            cell: info => {
              const valueProducts =
                info.getValue().map(item => item.product) || []
              return <NormalColumn label={valueProducts.join('／') ?? ''} />
            },
            header: '対象商品',
            size: 118,
            meta: {
              ...productsMeta,
              options: optionsPopover.product,
            },
          }),
        ),
        getColumnConfig(
          columnHelper.accessor('contact_person_name', {
            id: 'contact_person_name',
            cell: info => <NormalColumn label={info.getValue() ?? ''} />,
            header: '担当者',
            size: 128,
            meta: contactPerson,
          }),
        ),
      ].filter(Boolean),
    [
      columnHelper,
      formik.values.sort_by,
      is_news_reports,
      getColumnConfig,
      optionsPopover.product,
      optionsPopover.sauce,
    ],
  )

  const columns = React.useMemo(() => {
    switch (page) {
      case 'dashboard':
      case 'card-news':
        return columnsDashboard
      case 'company':
        return columnsCompany
      default:
        return columnsDashboard
    }
  }, [columnsCompany, columnsDashboard, page])

  const csvHeader = columns.map(c => ({
    label: String(c.header),
    key: String(c.accessorKey),
  }))

  // Convert values formik to filterKey
  const textFilterKey = useMemo(() => {
    const cusColumns = columns
      .map(item => {
        let header = String(item.header)
        let field_name =
          formik.values[item.meta?.field_name as keyof InitialValuesType] ?? ''
        let field_name_2 =
          formik.values[item.meta?.field_name_2 as keyof InitialValuesType]
        let is_show_filter = item.meta?.is_show_filter
        let separator = item.meta?.separator
        let text_change_boolean = item.meta?.text_change_boolean

        if ((!field_name && !field_name_2) || !is_show_filter)
          return { header, value: '' }

        if (separator && text_change_boolean) {
          return {
            header,
            value: String(
              Boolean(field_name_2)
                ? `${field_name}${separator}${text_change_boolean}`
                : `${field_name}`.trim(),
            ),
          }
        } else if (separator) {
          return {
            header,
            value: String(
              Boolean(field_name_2)
                ? `${field_name}${separator}${field_name_2}`
                : `${field_name}`.trim(),
            ),
          }
        }

        return { header, value: String(field_name).trim() }
      })
      .filter(item => item.value)

    if (cusColumns.length <= 0) return ''

    return cusColumns
      .map(({ header, value }) => {
        return `${header}-${value}`
      })
      .join('／')
  }, [columns, formik.values])

  // Get list news
  useEffect(() => {
    fetchDataNews(textFilterKey)
  }, [fetchDataNews, textFilterKey])

  // Convert textFilterKey to values formik
  const handleChangeKeyToValue = (text: string) => {
    const cusColumns = columns
      .filter(item => item.meta?.is_show_filter)
      .map(item => {
        let header = String(item.header)
        let field_name = item.meta?.field_name
        let field_name_2 = item.meta?.field_name_2
        let separator = item.meta?.separator
        let text_change_boolean = item.meta?.text_change_boolean
        return {
          ...(header && { header }),
          ...(field_name && { field_name }),
          ...(field_name_2 && { field_name_2 }),
          ...(separator && { separator }),
          ...(text_change_boolean && { text_change_boolean }),
        }
      })

    const arrField = text
      .split('／')
      .map(item => ({ header: item.split('-')[0], value: item.split('-')[1] }))

    let resultObject = {}

    cusColumns.forEach(item1 => {
      let matchingItem = arrField.find(item2 => item2.header === item1.header)
      if (matchingItem) {
        if (
          item1.field_name_2 &&
          item1.separator &&
          item1.text_change_boolean
        ) {
          return (resultObject = {
            ...resultObject,
            ...(item1.field_name && {
              [item1.field_name]: matchingItem.value.split(item1.separator)[0],
            }),
            [item1.field_name_2]: Boolean(
              matchingItem.value.split(item1.separator)[1],
            ),
          })
        } else if (item1.field_name_2 && item1.separator) {
          return (resultObject = {
            ...resultObject,
            ...(item1.field_name && {
              [item1.field_name]: matchingItem.value.split(item1.separator)[0],
            }),
            [item1.field_name_2]: matchingItem.value.split(item1.separator)[1],
          })
        }
        return (resultObject = {
          ...resultObject,
          ...(item1.field_name && {
            [item1.field_name]: matchingItem.value,
          }),
        })
      }
    })
    setValues(prev => ({ ...prev, ...resultObject }))
  }

  // Set values formik with filterkey is saved
  const handleClickFilterKeyItem = (value: string) => {
    setFilterKey(value ?? '')
    handleChangeKeyToValue(value ?? '')
    setPagination(prev => ({ ...prev, page: 1 }))
  }

  // add params of get list api to url
  useEffect(() => {
    const params = {
      page_size: String(pagination.page_size),
      page: String(pagination.page),
      ...(search_key && { search_key: search_key.trim() }),
      ...(start_date && {
        start_date: moment(start_date).format('YYYY-MM-DD'),
      }),
      ...(end_date && { end_date: moment(end_date).format('YYYY-MM-DD') }),
      ...(subject_company && { subject_company: subject_company.trim() }),
      ...(category && { category: category.trim() }),
      ...(contact_person_name && {
        contact_person_name: contact_person_name.trim(),
      }),
      ...(news_title && { news_title: news_title.trim() }),
      ...(favorite && {
        favorite: favorite ? 'true' : 'false',
      }),
      ...(score_article_gte && {
        score_article_gte: String(score_article_gte),
      }),
      ...(Boolean(score_article_lte) && {
        score_article_lte: String(score_article_lte),
      }),
      ...(sentiment && { sentiment }),
      ...(sauce && { sauce }),
      ...(products && { products }),
      sort_by: sort_by ?? 'news_date:desc',
      is_news_reports: is_news_reports,
      ...(Boolean(textFilterKey) && {
        filter_key: textFilterKey?.trim(),
      }),
    }

    const searchParams = new URLSearchParams(params).toString()
    navigate(`?${searchParams}`, { replace: true })
  }, [
    category,
    companyId,
    contact_person_name,
    end_date,
    favorite,
    formik.values,
    is_news_reports,
    navigate,
    news_title,
    pagination.page,
    pagination.page_size,
    products,
    sauce,
    score_article_gte,
    score_article_lte,
    search_key,
    sentiment,
    sort_by,
    start_date,
    subject_company,
    textFilterKey,
  ])

  return (
    <FormikProvider value={formik}>
      <Flex
        bgColor="white.base"
        borderRadius={4}
        direction="column"
        gap="16px"
        padding="12px 24px"
        width="100%"
      >
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          <Flex alignItems="center" gap="32px">
            <Text
              className="drag-vertical"
              color="black.100"
              cursor="pointer"
              display="inline-block"
              fontSize="lg"
              fontWeight="bold"
              letterSpacing="-0.02em"
              lineHeight="tall"
              minW={'fit-content'}
              px={'8px'}
            >
              記事検索
            </Text>
            <Flex alignItems="center" gap="16px">
              <SelectField
                height="40px"
                name="is_news_reports"
                options={optionTypeReport}
                placeholder="会社名"
                valueOption={optionTypeReport.find(
                  o => o.value === is_news_reports,
                )}
                width="140px"
                onChange={e => {
                  setFieldValue('is_news_reports', e?.value)
                  setPagination(prev => ({ ...prev, page: 1 }))
                  resetForm({
                    values: {
                      ...formik.initialValues,
                      is_news_reports: String(e?.value),
                    },
                  })
                }}
              />
              <InputField
                isInForm={false}
                name="search_key"
                placeholder="フリーワード検索"
                type="text"
                value={searchKey}
                width="242px"
                onChange={e => {
                  setSearchKey(e.target.value)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setFieldValue('search_key', searchKey)
                    setPagination(prev => ({ ...prev, page: 1 }))
                  }
                }}
              />
              <Flex alignItems="center">
                <DatePicker
                  clear={start_date ? true : false}
                  endDate={end_date}
                  height="40px"
                  isInvalid={!!errors?.start_date}
                  maxDate={end_date}
                  name={'start_date'}
                  placeholder={'2023/04/31'}
                  selectsStart={true}
                  startDate={start_date}
                  value={start_date}
                  width="120px"
                  onChange={value => {
                    setFieldValue('start_date', value)
                    setPagination(prev => ({ ...prev, page: 1 }))
                  }}
                  onKeyDown={e => e.preventDefault()}
                />
                <Text
                  color="black.100"
                  display="inline-block"
                  fontSize="sm"
                  fontWeight="normal"
                  lineHeight="shorter"
                  mx="8px"
                >
                  〜
                </Text>
                <DatePicker
                  clear={end_date ? true : false}
                  endDate={end_date}
                  height="40px"
                  isInvalid={!!errors?.end_date}
                  minDate={start_date}
                  name={'end_date'}
                  placeholder={'2023/04/31'}
                  selectsEnd={true}
                  startDate={start_date}
                  value={end_date}
                  width="120px"
                  onChange={value => {
                    setFieldValue('end_date', value)
                    setPagination(prev => ({ ...prev, page: 1 }))
                  }}
                  onKeyDown={e => e.preventDefault()}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            w="calc(100% - 803px)"
          >
            <Flex
              alignItems="center"
              bgColor="#F3F5F7"
              borderRadius="12px"
              flexDirection="row"
              justifyContent="flex-end"
              maxW="calc(100% - 24px)"
              minW={'102px'}
              padding="7px 16px 9px 16px"
            >
              <Text
                color="black.100"
                fontSize="sm"
                fontWeight="bold"
                lineHeight="shorter"
                minW="70px"
              >
                絞込条件：
              </Text>
              <Flex alignItems="center" w="calc(100% - 70px)">
                <Tooltip
                  bgColor="black"
                  color="white"
                  label={handleConvertIcon(textFilterKey || filterKey)}
                  maxW={'300px'}
                  minW={'200px'}
                  placement="top"
                  w={'100%'}
                >
                  <Text
                    color="black.100"
                    cursor="pointer"
                    fontSize="sm"
                    fontWeight="normal"
                    lineHeight="shorter"
                    overflow="hidden"
                    style={{
                      display: '-webkit-box',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                    }}
                    w="100%"
                    onClick={() => setIsShowSaveFilterModal(true)}
                  >
                    {handleConvertIcon(textFilterKey || filterKey)}
                  </Text>
                </Tooltip>
                {(textFilterKey || filterKey) && (
                  <Close
                    cursor="pointer"
                    fontSize="12px"
                    ml="6px"
                    onClick={handleResetFilterKey}
                  />
                )}
              </Flex>
            </Flex>
            <Menu>
              <MenuButton mb="5px">
                <MoreVertical color="gray.800" fontSize="2xl" opacity={0.3} />
              </MenuButton>
              <MenuList
                bgColor="black.300"
                borderRadius="20px"
                display="flex"
                flexDirection="column"
                gap="16px"
                maxH="194px"
                maxW="337px"
                overflow="auto"
                overflowY={'auto'}
                padding="24px"
                zIndex={2}
              >
                {dataMoreVertical.map((item, index) => (
                  <MenuItem
                    key={index}
                    bgColor="transparent"
                    gap="16px"
                    padding={0}
                  >
                    {item.link ? (
                      <Link
                        style={{ display: 'flex', gap: '16px' }}
                        target="_blank"
                        to={item.link}
                      >
                        <Image src={item.icon} />
                        <Text
                          color="white.50"
                          fontSize="sm"
                          fontWeight="medium"
                          letterSpacing="-0.02em"
                          lineHeight="shorter"
                        >
                          {item.title}
                        </Text>
                      </Link>
                    ) : item.export ? (
                      <>
                        <Box style={{ display: 'flex', gap: '16px' }}>
                          <Image src={item.icon} />
                          <Text
                            color="white.50"
                            fontSize="sm"
                            fontWeight="medium"
                            letterSpacing="-0.02em"
                            lineHeight="shorter"
                            onClick={async () => {
                              await handleExportCsv(textFilterKey)
                              setTimeout(
                                () => csvInstance.current.link.click(),
                                1000,
                              )
                            }}
                          >
                            {item.title}
                          </Text>
                        </Box>
                      </>
                    ) : (
                      <Flex justifyContent="space-between">
                        <Flex gap="16px">
                          <Image src={item.icon} />
                          <Text
                            color="white.50"
                            fontSize="sm"
                            fontWeight="medium"
                            letterSpacing="-0.02em"
                            lineHeight="shorter"
                            w="233px"
                            onClick={() => {
                              handleClickFilterKeyItem(item.keyword ?? '')
                            }}
                          >
                            {handleConvertIcon(item.title)}
                          </Text>
                        </Flex>
                        <Close
                          color="white.base"
                          cursor="pointer"
                          mt="4px"
                          onClick={() =>
                            item.id && handleDeleteConditionFilter(item.id)
                          }
                        />
                      </Flex>
                    )}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
        <DataTable
          columns={columns}
          data={dataNews?.data ?? []}
          handleSort={handleSort}
          isFilter={true}
          isPagination={true}
          isScroll={isScrollTable}
          isShowAction={false}
          loadingData={isLoading}
          maxH={isScrollTable ? 'calc(100vh - 232px)' : undefined}
          pagination={pagination}
          rangePage={[5, 10, 20]}
          setPagination={setPagination}
        />
      </Flex>
      <Modal
        children={
          <SaveKeyModal
            handleSaveConditionFilter={() =>
              handleSaveConditionFilter(textFilterKey)
            }
            isLoadingSaveKey={isLoadingSaveKey}
            setIsShowSaveFilterModal={setIsShowSaveFilterModal}
          />
        }
        isOpen={isShowSaveFilterModal}
        maxWidth="344px"
        onClose={() => setIsShowSaveFilterModal(false)}
      />
      <CSVLink
        ref={csvInstance}
        data={dataExport}
        filename={'記事一覧.csv'}
        headers={csvHeader}
        style={{ display: 'none' }}
        target="_blank"
      ></CSVLink>
    </FormikProvider>
  )
}
