/**
 * LinkSolidIcon icon
 * @constructor
 */
export const LinkSolidIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.21995 20C6.20025 20 4.31907 19.2523 2.9222 17.8942C-0.0238146 15.0285 0.0218573 10.3215 3.02391 7.40241L9.08093 1.51084C10.0823 0.53643 11.4156 0 12.8325 0C14.2495 0 15.583 0.53643 16.5844 1.51084C18.6507 3.5212 18.6507 6.79215 16.5844 8.80251L10.4977 14.7218C9.34724 15.8418 7.33932 15.8429 6.1846 14.7218C4.9971 13.5669 4.9971 11.6878 6.1846 10.5328L11.5309 5.33259C11.8407 5.03206 12.343 5.03103 12.6529 5.33156C12.9638 5.63106 12.9637 6.11827 12.6549 6.41982L7.30864 11.6201C6.73657 12.176 6.73657 13.0807 7.30864 13.6366C7.85956 14.174 8.82379 14.173 9.37472 13.6366L15.4614 7.71731C16.9122 6.30595 16.9122 4.00943 15.4614 2.59807C14.0571 1.23185 11.6092 1.23185 10.205 2.59807L4.14795 8.48968C1.80151 10.7708 1.75583 14.5813 4.04624 16.8091C5.1428 17.8758 6.62429 18.4625 8.21995 18.4625C9.85581 18.4625 11.4493 17.8245 12.5935 16.7116L17.9093 11.5401C18.2181 11.2396 18.7204 11.2385 19.0313 11.5391C19.3422 11.8386 19.3421 12.3258 19.0334 12.6273L13.7175 17.7989C12.2773 19.1969 10.2735 20 8.21995 20Z"
      fill="#0084FF"
    />
  </svg>
)
