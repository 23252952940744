import { useCallback, useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { Option } from 'types/conmon'
import { chatAIServices, storageServices } from 'services'

export type TalkStoryProps = {}

export const useTalkStory = (props: TalkStoryProps) => {
  const ref = useRef<any>()
  const accessToken = storageServices.getAccessToken()

  const [AIAnswer, setAIAnswer] = useState('')
  const [stateAIAnswer, setStateAIAnswer] = useState<{
    loading: boolean
    done: boolean
  }>({ loading: false, done: true })
  const [isDisableSelect, setIsDisableSelect] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [options, setOptions] = useState<{
    industry: Option[]
    story: Option[]
  }>({ industry: [], story: [] })

  const formik = useFormik({
    initialValues: {
      selectIndustry: null,
      selectTalkStory: null,
    },
    /**
     *
     */
    onSubmit() {},
  })

  const { values, setFieldValue } = formik

  /**
   * Show all text
   */
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const fetchDataOptions = useCallback(async () => {
    try {
      const [industryOptions, storyOptions] = await Promise.all([
        chatAIServices.getOptionsIndustry(),
        chatAIServices.getOptionsStory(),
      ])

      const convertIndustryOptions = industryOptions.data.map(option => ({
        label: option.name,
        value: option.id,
      }))

      const convertStoryOptions = storyOptions.data.map(option => ({
        label: option.title,
        value: option.id,
      }))

      setOptions(prev => ({
        ...prev,
        industry: convertIndustryOptions,
        story: convertStoryOptions,
      }))
    } catch (error) {
      console.log('error', error)
    }
  }, [])

  useEffect(() => {
    fetchDataOptions()
  }, [fetchDataOptions])

  useEffect(() => {
    if (ref.current?.clientHeight >= 168) {
      setIsShow(true)
    } else {
      setIsShow(false)
    }
  }, [AIAnswer])

  useEffect(() => {
    if (!accessToken || !values.selectIndustry || !values.selectTalkStory)
      return

    const newSocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/api/v1/advisor/news/ai_recommend/ws_stream`,
    )

    const handleSocketOpen = () => {
      console.log('WS connected')
      setStateAIAnswer(prev => ({ ...prev, loading: true, done: false }))
      setIsDisableSelect(true)

      const messAuthen = {
        type: 'auth_req',
        data: { Authorization: `Bearer ${accessToken}` },
      }

      newSocket.send(JSON.stringify(messAuthen))

      let message = {
        type: 'stream_req',
        data: {
          industry_id: values.selectIndustry,
          story_id: values.selectTalkStory,
        },
      }

      newSocket.send(JSON.stringify(message))
    }

    const handleSocketMessage = (event: { data: string }) => {
      const objMess = JSON.parse(event.data)

      if (objMess?.done === 1) {
        setAIAnswer(prev => {
          return prev.replace(/```/g, '')
        })
        setStateAIAnswer(prev => ({ ...prev, done: true }))
        return
      }

      switch (objMess.type) {
        case 'stream_res':
          setStateAIAnswer(prev => ({ ...prev, loading: false }))
          setAIAnswer(prev => {
            return (prev + objMess?.data).replace(/```html/g, '')
          })
          break
        default:
          break
      }
    }

    const handleSocketClose = () => {
      console.log('WebSocket is disconnected')
      setIsDisableSelect(false)
    }

    newSocket.addEventListener('open', handleSocketOpen)
    newSocket.addEventListener('message', handleSocketMessage)
    newSocket.addEventListener('close', handleSocketClose)

    return () => {
      newSocket.removeEventListener('open', handleSocketOpen)
      newSocket.removeEventListener('message', handleSocketMessage)
      newSocket.removeEventListener('close', handleSocketClose)
      newSocket.close()
    }
  }, [accessToken, values.selectIndustry, values.selectTalkStory])

  return {
    ...props,
    formik,
    options,
    values,
    setAIAnswer,
    setFieldValue,
    AIAnswer,
    toggleExpanded,
    expanded,
    setExpanded,
    stateAIAnswer,
    isShow,
    ref,
    isDisableSelect,
  }
}
