import { axiosWithToken } from 'libs/configureAxios'
import moment from 'moment'
import { BasicResponse } from 'types/conmon'
import {
  WordCloudMoreChartResponse,
  WordTimeLineChartResponse,
} from 'services/companyServices'
import {
  ConditionFilterNewResponse,
  ExtractPdfResponse,
  GetDataChartNewsStringResponse,
  GetNewsFavoriteResponse,
  GetOptionsIndustryResponse,
  GetOptionsProductRequest,
  GetOptionsProductResponse,
  GetOptionsSauceResponse,
  NewDetailResponse,
  NewsRequest,
  NewsResponse,
  WordCategoryChartRequest,
  WordCategoryChartResponse,
} from './newServices.type'

/**
 * @returns function that handle news services
 */
export class NewServices {
  /**
   * @returns function that handle getNews
   */
  async getNews(params: NewsRequest): Promise<NewsResponse> {
    const {
      page,
      page_size,
      search_key,
      start_date,
      end_date,
      textFilterKey,
      company_id,
      contact_person_name,
      favorite,
      news_title,
      score_article_gte,
      score_article_lte,
      sentiment,
      sort_by,
      is_news_reports,
    } = params
    const cusParams = {
      page_size,
      page,
      is_news_reports: is_news_reports,
      sort_by: sort_by ?? 'news_date:desc',
      ...(search_key && { search_key: search_key.trim() }),
      ...(start_date && {
        start_date: moment(start_date).format('YYYY-MM-DD'),
      }),
      ...(end_date && { end_date: moment(end_date).format('YYYY-MM-DD') }),
      ...(Boolean(textFilterKey) && {
        filter_key: textFilterKey?.trim(),
      }),
      ...(Boolean(company_id) && { company_id: Number(company_id) }),
      ...(contact_person_name && {
        contact_person_name: contact_person_name.trim(),
      }),
      ...(news_title && { news_title: news_title.trim() }),
      ...(score_article_gte && { score_article_gte }),
      ...(Boolean(score_article_lte) && {
        score_article_lte,
      }),
      ...(favorite && { favorite }),
      ...(sentiment && { sentiment }),
    }
    return (await axiosWithToken.get(`/v1/advisor/news`, { params: cusParams }))
      .data
  }

  /**
   * @returns function that handle getNewDetail
   */
  async getNewDetail(params: {
    id: string
    list_news_ai_id?: number
  }): Promise<NewDetailResponse> {
    return (
      await axiosWithToken.get(`/v1/advisor/news/${params.id}`, {
        params: { list_news_ai_id: params.list_news_ai_id },
      })
    ).data
  }

  /**
   * @returns function that handle getRelatedArticle
   */
  async getRelatedArticle(news_id: string): Promise<NewsResponse> {
    return (await axiosWithToken.get(`/v1/advisor/news/${news_id}/related`))
      .data
  }

  /**
   * @returns function that handle getConditionFilterNew
   */
  async getConditionFilterNew(): Promise<ConditionFilterNewResponse> {
    return (await axiosWithToken.get(`/v1/advisor/filter`)).data
  }

  /**
   * @returns function that handle saveConditionFilter
   */
  async saveConditionFilter(
    keyword: string,
  ): Promise<ConditionFilterNewResponse> {
    return (await axiosWithToken.post(`/v1/advisor/filter`, { keyword })).data
  }

  /**
   * @returns function that handle deleteConditionFilter
   */
  async deleteConditionFilter(id: number): Promise<ConditionFilterNewResponse> {
    return await axiosWithToken.delete(`/v1/advisor/filter/${id}`)
  }

  /**
   * @returns function that handle get news favorite
   */
  async getNewsFavorite(
    id: string,
  ): Promise<BasicResponse<GetNewsFavoriteResponse>> {
    return (await axiosWithToken.get(`/v1/advisor/news_favorite/news/${id}`))
      .data
  }

  /**
   * @returns function that handle favorite news
   */
  async actionsFavoriteNews(id: string) {
    return await axiosWithToken.post(`/v1/advisor/news_favorite/`, {
      news_id: id,
    })
  }

  /**
   * @returns function that handle remove favorite news
   */
  async actionsRemoveFavoriteNews(id: string) {
    return await axiosWithToken.delete(`/v1/advisor/news_favorite/`, {
      data: { news_id: id },
    })
  }

  /**
   * @returns function that get options sauce data
   */
  async getOptionsSauce(): Promise<BasicResponse<GetOptionsSauceResponse[]>> {
    return (await axiosWithToken.get(`/v1/advisor/news/sauce/`)).data
  }

  /**
   * @returns function that get options industry data
   */
  async getOptionsIndustry(): Promise<
    BasicResponse<GetOptionsIndustryResponse[]>
  > {
    return (await axiosWithToken.get(`/v1/advisor/company/industry_name/`)).data
  }

  /**
   * @returns function that get options products data
   */
  async getOptionsProduct(
    params: GetOptionsProductRequest,
  ): Promise<BasicResponse<GetOptionsProductResponse[]>> {
    return (
      await axiosWithToken.get(`/v1/advisor/product`, {
        params: { is_paginate: params.isPaginate },
      })
    ).data
  }

  /**
   * @returns function that get data chart with news type is string
   */
  async getDataChartNewsString(
    id: number,
  ): Promise<BasicResponse<GetDataChartNewsStringResponse>> {
    return (
      await axiosWithToken.get(
        `/v1/advisor/news/${id}/meta_analyze/word_les_set/`,
      )
    ).data
  }

  /**
   * @returns function that get data chart with news type is string
   */
  async getExtractPdf(id: number): Promise<{ message: string }> {
    return await axiosWithToken.get(
      `/v1/advisor/list_new_file/extract_pdf/${id}`,
    )
  }

  /**
   * @returns function that get data chart with news type is string
   */
  async addExtractPdf(params: {
    new_id: string
  }): Promise<BasicResponse<ExtractPdfResponse>> {
    return (
      await axiosWithToken.post(
        `/v1/advisor/list_new_file/get_summary_pdf/?new_id=${params.new_id}`,
      )
    ).data
  }

  /**
   * @returns function that get meta analyze categories
   */
  async getWordCategoryChart(
    params: WordCategoryChartRequest,
  ): Promise<BasicResponse<WordCategoryChartResponse>> {
    return (
      await axiosWithToken.get(
        `/v1/advisor/news/${params.news_id}/meta_analyze/categories/`,
        { params },
      )
    ).data
  }

  /**
   * @returns function that get meta analyze categories
   */
  async getWordCloudMoreChart(
    params: WordCategoryChartRequest,
  ): Promise<BasicResponse<WordCloudMoreChartResponse>> {
    return (
      await axiosWithToken.get(
        `/v1/advisor/company/${params.news_id}/meta_analyze/categories/compare_to/`,
        { params },
      )
    ).data
  }

  /**
   * @returns function that get meta analyze timeline
   */
  async getWordCloudTimeLineChart(params: {
    news_id: number
    type_of_process: number
    previous_times: number
    year?: number
  }): Promise<BasicResponse<WordTimeLineChartResponse[]>> {
    return (
      await axiosWithToken.get(
        `/v1/advisor/news/${params.news_id}/meta_analyze/timeline/`,
        { params },
      )
    ).data
  }
}
