import { errors } from 'constant'
import { regexKana } from 'constant/conmon'
import { yup } from 'libs'

export const companyField = [
  {
    label: '会社名',
    name: 'company_name',
    placeholder: '例：株式会社MILIZE',
  },
  {
    label: 'フリガナ',
    name: 'furigana',
    placeholder: '例：カブシキガイシャミライズ',
  },
  {
    label: '英語表記',
    name: 'englishNotation',
    placeholder: '例：MILIZE CO.,LTD',
  },
]

export const initialValues = {
  company_name: '',
  security_code: '',
  company_name_kana: '',
  company_name_en: '',
  contact_person_name: '',
  postCode_1: '',
  postCode_2: '',
  department: '',
  state_id: null,
  city_id: null,
  address1: '',
  address2: '',
  year: '',
  day: '',
  funds: null,
  month: '',
  company_url: '',
  average_age: null,
  number_employees: null,
  total_groups: null,
  representative_last_name_kanji: '',
  representative_first_name_kanji: '',
  representative_last_name_kata: '',
  representative_first_name_kata: '',
  contact_last_name_kanji: '',
  contact_first_name_kanji: '',
  contact_last_name_kata: '',
  contact_first_name_kata: '',
  phone: '',
  sale_phone: '',
  customer_company: [
    {
      name: null,
    },
  ],
  supplier_company: [
    {
      name: null,
    },
  ],
  offices: [
    {
      office_type: null,
      city_id: null,
    },
  ],
  business_activity: [
    {
      category_id: null,
      description: null,
    },
  ],

  company_employees: [
    {
      position_id: null,
      first_name_kanji: null,
      last_name_kanji: null,
      first_name_kata: null,
      last_name_kata: null,
    },
  ],
  bank: [
    {
      name: null,
    },
  ],
  performance_trends: {
    Fiscal_Closing_Month__c: '',
    Sales_Ordinance_2_3__c: '',
    Sales_Ordinance_3_3__c: '',
    Sales_Ordinance_4_3__c: '',
    Operating_Profit_Ordinance_2_3__c: '',
    Operating_Profit_Ordinance_3_3__c: '',
    Operating_Profit_Ordinance_4_3__c: '',
    Ordinary_Profit_Ordinance_2_3__c: '',
    Ordinary_Profit_Ordinance_3_3__c: '',
    Ordinary_Profit_Ordinance_4_3__c: '',
    Net_Income_Ordinance_2_3__c: '',
    Net_Income_Ordinance_3_3__c: '',
    Net_Income_Ordinance_4_3__c: '',
    Declared_Income_Ordinance_2_3__c: '',
    Declared_Income_Ordinance_3_3__c: '',
    Declared_Income_Ordinance_4_3__c: '',
  },
}

export const companyRegistrationSchema = yup.object({
  company_name: yup
    .string()
    .required(errors.required)
    .max(100, '100字数以下で入力してください。')
    .label('company_name')
    .trim(),
  security_code: yup
    .string()
    .required(errors.required)
    .max(4, '4字数以下で入力してください。')
    .label('security_code')
    .trim(),
  company_name_kana: yup
    .string()
    .required(errors.required)
    .max(255, '255字数以下で入力してください。')
    .label('company_name_kana')
    .matches(regexKana, 'カタカナのみ入力してください。')
    .trim(),
  company_name_en: yup
    .string()
    .required(errors.required)
    .max(255, '255字数以下で入力してください。')
    .label('company_name_en')
    .trim(),
  department: yup
    .string()
    .max(255, '255字数以下で入力してください。')
    .required(errors.required)
    .label('department')
    .trim(),
  postCode_1: yup
    .string()
    .max(3, '3字数以下で入力してください。')
    .required(errors.required)
    .label('postCode_1')
    .trim(),
  postCode_2: yup
    .string()
    .max(4, '4字数以下で入力してください。')
    .required(errors.required)
    .label('postCode_2')
    .trim(),
  contact_person_name: yup
    .string()
    .required(errors.required)
    .max(255, '255字数以下で入力してください。')
    .label('contact_person_name')
    .trim(),
  state_id: yup.string().required(errors.required).label('state_id'),
  city_id: yup.string().required(errors.required).label('city_id'),
  address1: yup
    .string()
    .required(errors.required)
    .max(255, '255字数以下で入力してください。')
    .label('address1')
    .trim(),
  address2: yup
    .string()
    .required(errors.required)
    .max(255, '255字数以下で入力してください。')
    .label('address2')
    .trim(),
  phone: yup
    .string()
    .required(errors.required)
    .max(20, '20字数以下で入力してください。')
    .label('phone')
    .trim(),
  sale_phone: yup
    .string()
    .required(errors.required)
    .max(20, '20字数以下で入力してください。')
    .label('sale_phone')
    .trim(),
  contact_first_name_kanji: yup
    .string()
    .required(errors.required)
    .max(255, '255字数以下で入力してください。')
    .label('contact_first_name_kanji')
    .trim(),
  contact_last_name_kanji: yup
    .string()
    .required(errors.required)
    .max(255, '255字数以下で入力してください。')
    .label('contact_last_name_kanji')
    .trim(),
  contact_first_name_kata: yup
    .string()
    .required(errors.required)
    .max(255, '255字数以下で入力してください。')
    .label('contact_first_name_kata')
    .matches(regexKana, 'カタカナのみ入力してください。')
    .trim(),
  contact_last_name_kata: yup
    .string()
    .required(errors.required)
    .max(255, '255字数以下で入力してください。')
    .label('contact_last_name_kata')
    .matches(regexKana, 'カタカナのみ入力してください。')
    .trim(),
  company_employees: yup.array().of(
    yup.object().shape(
      {
        position_id: yup
          .string()
          .nullable()
          .when(
            [
              'first_name_kanji',
              'last_name_kanji',
              'first_name_kata',
              'last_name_kata',
            ],
            (
              [
                first_name_kanji,
                last_name_kanji,
                first_name_kata,
                last_name_kata,
              ],
              schema,
            ) =>
              first_name_kanji ||
              last_name_kanji ||
              first_name_kata ||
              last_name_kata
                ? schema.required(errors.required)
                : schema,
          ),
        // first_name_kanji: yup
        //   .string()
        //   .nullable()
        //   .when(['position_id'], ([position_id], schema) =>
        //     position_id ? schema.required(errors.required) : schema,
        //   ),
        // last_name_kanji: yup
        //   .string()
        //   .nullable()
        //   .when(['position_id'], ([position_id], schema) =>
        //     position_id ? schema.required(errors.required) : schema,
        //   ),
      },
      [['first_name_kanji', 'last_name_kanji']],
    ),
  ),
})
