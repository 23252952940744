import { ROLE, ROUTES } from 'constant'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDisclosure } from '@chakra-ui/react'
import { auth } from 'context/Auth/auth.selector'
import { useSelector } from 'react-redux'
import { BreadcrumDetailSelector } from 'context/Breadcrumb/breadcrumb.selector'
import { storageServices } from 'services'

export type HeaderProps = { setShowDrawer: Dispatch<SetStateAction<boolean>> }

export type DataBreadCrumbType = {
  path: string
  breadcrumb: {
    text: string
    link: string
  }[]
  tab: string
  id?: boolean
}

export const useHeader = (props: HeaderProps) => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const breadcrumbDetail = useSelector(BreadcrumDetailSelector)
  const { id } = useParams()
  const currentUser = useSelector(auth)
  const isAdmin = storageServices.getRoleUser() === ROLE.ADMIN
  const [loading, setLoading] = useState(false)
  const currentScreenChatAI = `/${pathname.split('/')[1]}` === ROUTES.CHAT_AI
  const currentScreenDashBoard =
    `/${pathname.split('/')[1]}` === ROUTES.DASHBOARD
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [img, setImg] = useState(currentUser?.avatar_url)

  const fetchImage = useCallback(async () => {
    if (!currentUser?.avatar_url) return
    const res = await fetch(currentUser?.avatar_url)
    const imageBlob = await res.blob()
    const imageObjectURL = URL.createObjectURL(imageBlob)
    setImg(imageObjectURL)
  }, [currentUser])
  /**
   * switch between ChatAI screen and Dashboard screen
   *
   */
  const handleSwitchHome = () => {
    setLoading(true)
    if (currentScreenChatAI) {
      navigate(ROUTES.DASHBOARD)
    } else if (currentScreenDashBoard) {
      navigate(ROUTES.CHAT_AI)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchImage()
  }, [fetchImage])
  return {
    ...props,
    loading,
    isAdmin,
    navigate,
    breadcrumbDetail,
    id,
    pathname,
    search,
    currentScreenChatAI,
    currentScreenDashBoard,
    handleSwitchHome,
    currentUser,
    onOpen,
    isOpen,
    onClose,
    img,
  }
}
