/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, FC, useRef } from 'react'
import { Button, CloseButton, Flex, Text } from '@chakra-ui/react'
import { Input } from 'components/fromAdvisor/elements'
import styled from '@emotion/styled'
import { LinkSolidIcon } from 'components/fromAdvisor/elements/Icons'
import { ErrorForm } from '../../../../../../modules/Form'
import { InputUrlProps } from './ConsultationContentList.type'

/**
 * InputUrl component page.
 * @return {JSX.Element} InputUrl component.
 */
export const InputUrl: FC<InputUrlProps> = ({
  url,
  file,
  errors,
  onSubmitUrl,
  onChangeUrl,
  onUploadFile,
  onClearFileUploaded,
  isLoadingUploadFile,
  setIsShowModalSelectArticle,
  setOldQuestion,
  question,
  isAskMore,
  questionAskMore,
  nameFileUpload,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  /**
   * @returns function that handle upload file
   */
  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files[0]) onUploadFile(files[0])
  }

  /**
   * @returns function that handle click upload file
   */
  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <Flex flexDirection={'column'} gap={2} w={'full'}>
      <Text
        color={'black.100'}
        fontSize={'xs'}
        lineHeight={'15.6px'}
        opacity={0.3}
      >
        分析したい記事のURLを入力、またはPDFをアップロードしてください。
      </Text>
      <Flex flexDirection={'column'}>
        <InputStyled
          isDisabled={!!file}
          placeholder={'URL'}
          value={url}
          onBlur={onSubmitUrl}
          onChange={event => onChangeUrl(event.target.value.trim())}
          onKeyDown={onSubmitUrl}
        />
        {errors?.url?.message && <ErrorForm error={errors?.url?.message} />}
      </Flex>
      <Button
        bg={'blue.800'}
        borderColor={'blue.800'}
        borderRadius={'12px'}
        h={10}
        isDisabled={!!url}
        isLoading={!url && isLoadingUploadFile}
        leftIcon={<LinkSolidIcon />}
        rightIcon={
          file && (
            <CloseButton
              _hover={{ background: 'transparent' }}
              onClick={onClearFileUploaded}
            />
          )
        }
        variant={'outline'}
        w={'full'}
        onClick={handleFileSelect}
      >
        <Text
          color={'blue.900'}
          fontSize={'sm'}
          fontWeight={'bold'}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
        >
          {Boolean(nameFileUpload) ? nameFileUpload : 'ファイルをアップロード'}
        </Text>
      </Button>
      {/* <Button
        bg={'blue.800'}
        borderColor={'blue.800'}
        color={'blue.900'}
        fontSize={'sm'}
        fontWeight={'bold'}
        h={10}
        text="Select article"
        variant={'outline'}
        w={'full'}
        onClick={() => {
          if (isAskMore) {
            setOldQuestion(questionAskMore)
          } else {
            setOldQuestion(question)
          }
          setIsShowModalSelectArticle(true)
        }}
      /> */}
      <input
        ref={fileInputRef}
        accept=".pdf,.doc"
        style={{ display: 'none' }}
        type="file"
        value={''}
        onChange={handleUploadFile}
      />
    </Flex>
  )
}

const InputStyled = styled(Input)`
  width: ${props => props.theme.sizes.full};
  height: ${props => props.theme.sizes[10]};
  background: ${props => props.theme.colors.white.base};
  border-color: ${props => props.theme.colors.blue[100]};
  border-width: ${props => props.theme.space.px};
`
