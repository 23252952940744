import { useToast } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { ROUTES, icons } from 'constant'
import { useFormik } from 'formik'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { newServices } from 'services'
import { ConditionFilterNewType, NewsResponse } from 'services/newServices'
import { Option } from 'types/conmon'

export type DataMoreVerticalType = {
  icon: string
  title: string
  link?: string
  export?: boolean
  keyword?: string
  id?: number
}

export type SearchPostProps = {
  perPageTable?: number
  isDetailCompany?: boolean
  page: string
}

export type InitialValuesType = {
  search_key: string
  start_date: Date | undefined
  end_date: Date | undefined
  subject_company: string
  category: string
  contact_person_name: string
  news_title: string
  favorite: boolean
  score_article_gte: number | undefined
  score_article_lte: number | undefined
  sentiment: string
  sauce: string
  products: string
  sort_by: string
  is_news_reports: string
}

const optionTypeReport = [
  { label: 'ニュース記事', value: 'false' },
  { label: 'IR資料', value: 'true' },
]

export const useSearchPost = (props: SearchPostProps) => {
  const { perPageTable } = props
  const { id: companyId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname, search } = location
  const queryParams = new URLSearchParams(search)

  const urlPageSize = queryParams.get('page_size')
    ? Number(queryParams.get('page_size'))
    : null
  const urlPage = queryParams.get('page')
    ? Number(queryParams.get('page'))
    : null

  const urlSearchKey = queryParams.get('search_key') ?? ''
  const urlStartDate = queryParams.get('start_date') ?? ''
  const urlEndDate = queryParams.get('end_date') ?? ''
  const urlSubjectCompany = queryParams.get('subject_company') ?? ''
  const urlCategory = queryParams.get('category') ?? ''
  const urlPersonName = queryParams.get('contact_person_name') ?? ''
  const urlNewsTitle = queryParams.get('news_title') ?? ''
  const urlFavorite = queryParams.get('favorite') ?? 'false'
  const urlScoreGTE = queryParams.get('score_article_gte') ?? ''
  const urlScoreLTE = queryParams.get('score_article_lte') ?? ''
  const urlSentiment = queryParams.get('sentiment') ?? ''
  const urlSauce = queryParams.get('sauce') ?? ''
  const urlProducts = queryParams.get('products') ?? ''
  const urlSortBy = queryParams.get('sort_by')
  const urlFilterKey = queryParams.get('filter_key') ?? ''
  const typeReport = queryParams.get('is_news_reports')

  const csvInstance = useRef<any | null>(null)
  const toast = useToast()

  const [searchKey, setSearchKey] = useState(urlSearchKey)
  const [isShowSaveFilterModal, setIsShowSaveFilterModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSaveKey, setIsLoadingSaveKey] = useState(false)
  const [optionsPopover, setOptionsPopover] = useState<{
    [key: string]: Option[]
  }>({
    sauce: [],
    category: [],
    product: [],
  })
  const [dataNews, setDataNews] = useState<NewsResponse>()
  const [filterKey, setFilterKey] = useState(urlFilterKey)
  const [conditionFilter, setConditionFilter] =
    useState<ConditionFilterNewType[]>()
  const [dataExport, setDataExport] = useState<any[]>([])
  const [pagination, setPagination] = useState<{
    page_size: number
    page: number
    page_count: number
    total_count: number
  }>({
    page_size: urlPageSize || perPageTable || 5,
    page: urlPage || 1,
    page_count: 1,
    total_count: 0,
  })

  const formik = useFormik<InitialValuesType>({
    initialValues: {
      search_key: urlSearchKey,
      start_date: urlStartDate ? new Date(urlStartDate) : undefined,
      end_date: urlEndDate ? new Date(urlEndDate) : undefined,
      subject_company: urlSubjectCompany,
      category: urlCategory,
      contact_person_name: urlPersonName,
      news_title: urlNewsTitle,
      favorite: urlFavorite === 'true' ? true : false,
      score_article_gte: urlScoreGTE ? +urlScoreGTE : undefined,
      score_article_lte: urlScoreLTE ? +urlScoreLTE : undefined,
      sentiment: urlSentiment,
      sauce: urlSauce,
      products: urlProducts,
      sort_by: urlSortBy ?? 'news_date:desc',
      is_news_reports: typeReport ?? 'false',
    },
    /**
     *
     */
    onSubmit() {},
    enableReinitialize: false,
  })

  const { setFieldValue, values, resetForm } = formik

  const fetchOptionsPopover = useCallback(async () => {
    try {
      const [dataSauce, dataIndustry, dataProduct] = await Promise.all([
        newServices.getOptionsSauce(),
        newServices.getOptionsIndustry(),
        newServices.getOptionsProduct({
          isPaginate: false,
        }),
      ])

      const optionsSauce = dataSauce.data.map(item => ({
        label: item.sauce,
        value: item.sauce,
      }))

      const optionsCategory = dataIndustry.data.map(item => ({
        label: item.industry_name,
        value: item.industry_name,
      }))

      const optionsProduct = dataProduct.data.map(item => ({
        label: item.product_name,
        value: item.product_name,
      }))

      setOptionsPopover(prev => ({
        ...prev,
        sauce: optionsSauce,
        category: optionsCategory,
        product: optionsProduct,
      }))
    } catch (error) {}
  }, [setOptionsPopover])

  /**
   * handleDeleteConditionFilter
   */
  const handleDeleteConditionFilter = async (id: number) => {
    try {
      await newServices.deleteConditionFilter(id)

      const res = await newServices.getConditionFilterNew()

      if (res.is_success) {
        setConditionFilter(res.data)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          status: 'error',
          description: error.message,
        })
      }
    }
  }

  /**
   * handleConvertIcon
   */
  const handleConvertIcon = (text: string) => {
    return text
      .replace('positive', 'ポジティブ')
      .replace('negative', 'ネガティブ')
      .replace('neutral', 'ニュートラル')
  }

  const dataMoreVertical: DataMoreVerticalType[] = [
    ...(pathname === ROUTES.DASHBOARD ? [1] : []).map(_ => ({
      icon: icons.WINDOW_RESTORE,
      title: '新しいウィンドウで開く',
      link: `${ROUTES.CARD_NEWS}`,
    })),
    {
      icon: icons.DOWNLOAD,
      title: 'エクスポート',
      export: true,
    },
    ...((conditionFilter && conditionFilter) || []).map((condition, index) => ({
      icon: icons.FILTER_LIST,
      title: `絞込条件${index + 1}：${condition.keyword}`,
      keyword: condition.keyword,
      id: condition.id,
    })),
  ]

  /**
   * handleSaveConditionFilter
   */
  const handleSaveConditionFilter = async (text: string) => {
    setIsLoadingSaveKey(true)

    try {
      await newServices.saveConditionFilter(text)

      setIsLoadingSaveKey(false)
      setIsShowSaveFilterModal(false)
    } catch (error) {
      setIsLoadingSaveKey(false)

      if (error instanceof AxiosError) {
        toast({
          status: 'error',
          description: error.message,
        })
      }
    }
  }

  const getColumnConfig = (config: any) => {
    if (values.is_news_reports === 'true') {
      return null
    }
    return config
  }

  /**
   * handleExportCsv
   */
  const handleExportCsv = async (textFilterKey: string) => {
    try {
      const cusParams = {
        page_size: -1,
        page: 1,
        textFilterKey: textFilterKey,
        company_id: Number(companyId),
        ...values,
        subject_company: values.subject_company.trim(),
        contact_person_name: values.contact_person_name.trim(),
        news_title: values.news_title.trim(),
      }
      const { data } = await newServices.getNews(cusParams)
      const cusData = data.map(d => ({
        ...d,
        sentiment: handleConvertIcon(d?.sentiment),
        products: d.products.map(p => p.product).join(', '),
      }))

      setDataExport(cusData ?? [])
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log('error', error)
      }
    }
  }

  const fetchDataNews = useCallback(
    async (textFilterKey: string) => {
      try {
        setIsLoading(true)

        const objParams = {
          page_size: pagination.page_size,
          page: pagination.page,
          textFilterKey: textFilterKey,
          company_id: Number(companyId),
          ...values,
          subject_company: values.subject_company.trim(),
          contact_person_name: values.contact_person_name.trim(),
          news_title: values.news_title.trim(),
        }

        const res = await newServices.getNews(objParams)

        if (res.is_success) {
          setDataNews(res)
          if (res.paginate) {
            setPagination(res.paginate)
          }
        }

        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)

        if (error instanceof AxiosError) {
          console.log('error', error)
        }
      }
    },
    [pagination.page_size, pagination.page, companyId, values],
  )

  /**
   * handleResetFilterKey
   */
  const handleResetFilterKey = () => {
    setFilterKey('')
    formik.resetForm({
      values: {
        search_key: values.search_key,
        start_date: values.start_date,
        end_date: values.end_date,
        subject_company: '',
        category: '',
        contact_person_name: '',
        news_title: '',
        favorite: false,
        score_article_gte: undefined,
        score_article_lte: undefined,
        sentiment: '',
        sauce: '',
        products: '',
        sort_by: 'news_date:desc',
        is_news_reports: values.is_news_reports,
      },
    })

    setPagination(prev => ({ ...prev, page: 1 }))
  }

  /**
   * handleSort
   */
  const handleSort = (field: string) => {
    const valueSort = values.sort_by.split(':')[1]

    setFieldValue(
      'sort_by',
      `${field}:${valueSort === 'desc' ? 'asc' : 'desc'}`,
    )
  }

  useEffect(() => {
    fetchOptionsPopover()
  }, [fetchOptionsPopover])

  useEffect(() => {
    ;(async () => {
      try {
        const res = await newServices.getConditionFilterNew()
        if (res.is_success) {
          setConditionFilter(res.data)
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          console.log('error', error)
        }
      }
    })()
  }, [isShowSaveFilterModal, setConditionFilter])

  useEffect(() => {
    if (pathname === ROUTES.DASHBOARD && !search) {
      resetForm({
        values: {
          search_key: '',
          start_date: undefined,
          end_date: undefined,
          subject_company: '',
          category: '',
          contact_person_name: '',
          news_title: '',
          favorite: false,
          score_article_gte: undefined,
          score_article_lte: undefined,
          sentiment: '',
          sauce: '',
          products: '',
          sort_by: 'news_date:desc',
          is_news_reports: 'false',
        },
      })
      setPagination({
        page_size: perPageTable || 5,
        page: 1,
        page_count: 1,
        total_count: 0,
      })
      setFilterKey('')
    }
  }, [pathname, perPageTable, resetForm, search])

  return {
    ...props,
    csvInstance,
    searchKey,
    setSearchKey,
    isShowSaveFilterModal,
    setIsShowSaveFilterModal,
    isLoading,
    isLoadingSaveKey,
    optionsPopover,
    dataNews,
    filterKey,
    setFilterKey,
    handleDeleteConditionFilter,
    setConditionFilter,
    handleConvertIcon,
    dataMoreVertical,
    dataExport,
    handleSaveConditionFilter,
    pagination,
    setPagination,
    handleExportCsv,
    formik,
    fetchDataNews,
    handleResetFilterKey,
    handleSort,
    optionTypeReport,
    getColumnConfig,
    navigate,
    queryParams,
    companyId,
  }
}
