import { Compare, Menu } from 'components/atoms/Icons'
import { ADMIN_ROUTES, ROUTES, images } from 'constant'
import { FC, useMemo } from 'react'
import {
  Flex,
  Text,
  Button,
  Avatar,
  Image,
  Breadcrumb,
  Tooltip,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react'
import { dataBreadcrumb } from 'constant/conmon'
import { Loading } from '../Loading'
import { ProfileModal } from '../ProfileModal'
import { DataBreadCrumbType, HeaderProps, useHeader } from './hook'

/**
 * Header molecules
 * @constructor
 */
export const Header: FC<HeaderProps> = props => {
  const {
    loading,
    isAdmin,
    navigate,
    breadcrumbDetail,
    id,
    pathname,
    search,
    currentScreenChatAI,
    currentScreenDashBoard,
    handleSwitchHome,
    currentUser,
    onOpen,
    isOpen,
    onClose,
    setShowDrawer,
    img,
  } = useHeader(props)

  const handleBreadcrumb = useMemo(() => {
    let data: DataBreadCrumbType | undefined = undefined
    if (!search) {
      data = dataBreadcrumb.find(b => b.path === pathname)
    } else {
      data = dataBreadcrumb.find(
        b => b.path === pathname && search.includes(b.tab),
      )
    }
    if (id) {
      data = dataBreadcrumb.find(b => `${b.path}/${id}` === pathname && b.id)
      if (!data) return
      data = {
        ...data,
        breadcrumb: data.breadcrumb.concat(breadcrumbDetail),
      }
    }
    if (!data) return

    return data.breadcrumb.map((b, index) => {
      const currentPage = index + 1 === data?.breadcrumb.length
      if (currentPage && data?.id) {
        return (
          <Tooltip
            key={index}
            bgColor="black"
            color="white"
            label={b.text}
            placement="top"
          >
            <BreadcrumbItem key={index} isCurrentPage={currentPage}>
              <BreadcrumbLink
                color="white.50"
                fontSize="sm"
                fontWeight={currentPage ? 'bold' : 'normal'}
                href={b.link}
                letterSpacing="-0.02em"
                lineHeight="shorter"
                maxW={'275px'}
                opacity={currentPage ? 1 : 0.7}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {b.text}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Tooltip>
        )
      } else {
        return (
          <BreadcrumbItem key={index} isCurrentPage={currentPage}>
            <BreadcrumbLink
              color="white.50"
              fontSize="sm"
              fontWeight={currentPage ? 'bold' : 'normal'}
              href={b.link}
              letterSpacing="-0.02em"
              lineHeight="shorter"
              minW={'fit-content'}
              opacity={currentPage ? 1 : 0.7}
            >
              {b.text}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )
      }
    })
  }, [breadcrumbDetail, id, pathname, search])

  if (loading) {
    return <Loading />
  }

  return (
    <Flex
      alignItems="center"
      boxSizing="border-box"
      direction="row"
      height="64px"
      justifyContent="space-between"
      width="100%"
    >
      <Flex alignItems="center" direction="row">
        <Image
          alt="Dan Abramov"
          cursor={'pointer'}
          h={'auto'}
          padding="21px 32px 17px 8px"
          src={images.LOGO}
          w={'205px'}
          onClick={() => {
            isAdmin ? navigate(ADMIN_ROUTES.ROOT) : navigate(ROUTES.DASHBOARD)
          }}
        />
        {isAdmin && (
          <Text
            border="1px solid #fefefe"
            borderRadius="24px"
            color="white.50"
            fontSize="16px"
            fontWeight="bold"
            h={'32px'}
            lineHeight={'28px'}
            mr={'8px'}
            padding="0 15px"
          >
            管理画面
          </Text>
        )}
        <Breadcrumb
          ml={'16px'}
          separator={
            <Text
              color="#E8ECEFBF"
              fontSize="sm"
              fontWeight="normal"
              lineHeight="shorter"
              opacity={0.3}
            >
              /
            </Text>
          }
        >
          {handleBreadcrumb}
        </Breadcrumb>
      </Flex>
      <Flex alignItems="center" direction="row" gap="24px">
        {!isAdmin && (
          <Button
            _hover={{ bgColor: 'black.50', color: 'purple.250' }}
            alignItems="center"
            bgColor="black.50"
            borderRadius="20px"
            color="purple.250"
            display="flex"
            justifyContent="space-between"
            leftIcon={
              currentScreenChatAI ? (
                <Compare color="blue.900" fontSize="2xl" />
              ) : undefined
            }
            padding={
              currentScreenChatAI
                ? '8px 13px 8px 15px'
                : currentScreenDashBoard
                ? '8px 15px 8px 13px'
                : ''
            }
            rightIcon={
              currentScreenDashBoard ? (
                <Compare color="blue.900" fontSize="2xl" />
              ) : undefined
            }
            variant="solid"
            w="163px"
            onClick={handleSwitchHome}
          >
            <Text
              color="purple.250"
              fontSize="sm"
              fontWeight="medium"
              letterSpacing="-0.02em"
              lineHeight="shorter"
              opacity={0.75}
              w={currentScreenDashBoard ? '97px' : 'auto'}
            >
              {currentScreenChatAI
                ? 'ダッシュボード'
                : currentScreenDashBoard
                ? 'AIチャット'
                : ''}
            </Text>
          </Button>
        )}
        <Avatar
          cursor="pointer"
          name={currentUser?.email}
          size="sm"
          src={img}
          onClick={onOpen}
        />
        <Menu
          color="blue.900"
          cursor="pointer"
          fontSize="24px"
          margin="8px 4px"
          onClick={() => setShowDrawer(true)}
        />
      </Flex>
      <ProfileModal
        currentUser={currentUser}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  )
}
